<template>
  <form @submit="upload">
    <div class="wrapper">
      <section class="left-pane">
        <label
          >Select Video...
          <input
            type="file"
            name="file"
            ref="file"
            accept="video/*"
            @change="createPreview"
          />
        </label>
        <table class="props" v-show="canUpload">
          <tr>
            <th>Width</th>
            <td>{{ videoWidth }}</td>
          </tr>
          <tr>
            <th>Height</th>
            <td>{{ videoHeight }}</td>
          </tr>
          <tr>
            <th>Media-Type</th>
            <td>{{ mediaType }}</td>
          </tr>
          <tr>
            <th>CurrentTime</th>
            <td>{{ videoCurrentTime }}</td>
          </tr>
          <tr>
            <th>Duration</th>
            <td>{{ videoDuration }}</td>
          </tr>
          <tr>
            <th colspan="2">Metadata</th>
          </tr>
          <tr>
            <th>Title</th>
            <td><input type="text" name="title" v-model="title" /></td>
          </tr>
          <tr>
            <th>Category &amp; Tags</th>
            <td><input type="text" name="tags" v-model="tags" /></td>
          </tr>
        </table>
        <input v-show="canUpload" class="submit" type="submit" value="Upload" />
      </section>

      <section class="right-pane">
        <div class="video-container">
          <div>
            <video
              class="bordered"
              controls
              muted
              ref="video"
              v-show="canUpload"
              :width="videoComponentWidth"
              :height="videoComponentHeight"
              @loadedmetadata="onLoadedVideoMetadata"
              @timeupdate="onVideoTimeUpdate"
            ></video>
          </div>
        </div>
      </section>
    </div>
  </form>
</template>

<script>
import _ from "lodash";

export default {
  name: "create-annotation",
  data() {
    return {
      title: "",
      tags: "",

      mediaType: "unknown",
      videoWidth: 0,
      videoHeight: 0,

      videoComponentWidth: 640,
      videoComponentHeight: 480,

      videoCurrentTime: 0,
      videoDuration: 0,

      previewTimer: null,

      canUpload: false,

      options: {
        targetFps: 1,
        sampleInterval: 1000,
      },
    };
  },

  methods: {
    createPreview(event) {
      const el = event.target;
      const file = el.files[0];
      const mime = file.type;
      const { video } = this.$refs;
      const canPlay = video.canPlayType(mime);

      if (canPlay !== "") {
        this.mediaType = mime;
        const fileURL = window.URL.createObjectURL(file);
        video.src = fileURL;
        this.canUpload = true;
        this.title = file.name.substring(0, file.name.lastIndexOf("."));
      }
    },

    onLoadedVideoMetadata(event) {
      event.stopPropagation();
      const el = event.target;
      const scale = this.videoComponentWidth / el.videoWidth;
      this.videoWidth = el.videoWidth;
      this.videoHeight = el.videoHeight;
      this.videoComponentHeight = this.videoHeight * scale;
      this.videoCurrentTime = el.currentTime;
      this.videoDuration = el.duration;

      this.$refs.video.play();
    },

    onVideoTimeUpdate(event) {
      event.stopPropagation();
      const el = event.target;
      const { currentTime, duration } = el;
      this.videoCurrentTime = currentTime;
    },

    async upload(event) {
      event.preventDefault();
      const el = event.target;
      const file = this.$refs.file?.files[0];

      console.log(file);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("title", this.title);
      formData.append("tags", this.tags);
      formData.append("options", JSON.stringify(this.options));

      await this.$http.post(`video`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 1fr;
  height: calc(100vh - 200px);
  background: #eee;
}

.header {
  grid-column: 1 / 3;
  grid-row: 1;
  height: 50px;
}

.left-pane {
  grid-column: 1;
  grid-row: 1;

  label {
    display: inline-block;
    padding: 0.5em 5px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    background-color: #aaf;
    cursor: pointer;
    border: 1px solid #aaa;
    border-radius: 25px;
    width: 95%;
  }

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.right-pane {
  grid-column: 2;
  grid-row: 1;

  .video-container {
    display: flex;

    video,
    canvas {
      border: 1px solid #ccc;
    }
  }
}

.props {
  width: 100%;

  th,
  td {
    padding: 3px 10px;
  }

  th {
    background-color: #aaa;
  }

  td {
    background-color: #ccc;
  }
}

.submit {
  position: relative;
  float: left;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 24px;
  background-color: lightgreen;
}
</style>