<template>
  <ul class="columns">
    <Card v-for="(item, index) in items" :key="index" :item="item"/>
  </ul>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'tile',
  components: {
    Card,
  },
  props: ['items', 'width'],
}
</script>

<style lang="scss" scoped>
$card-width: 280px;

.columns {
  display: flex;
  flex-wrap: wrap;
  column-width: $card-width;
  column-gap: 15px;
  justify-content: space-between;
}
</style>