import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// import Auth?

import Home from '@/views/Home.vue';
import MobileHome from '@/views/MobileHome.vue';
import VideoView from '@/views/VideoView.vue';
import RemoteControlView from '@/views/RemoteControlView.vue';
import VideoUpload from '@/views/VideoUpload.vue';
import AnnotationTool from '@/views/AnnotationTool.vue';
import About from '@/views/About.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { unauthorized: true },
  },
  {
    path: '/m',
    name: 'MobileHome',
    component: MobileHome,
    meta: { layout: 'FullScreenLayout', unauthorized: true },
  },
  {
    path: '/watch/:videoId(.*)',
    name: 'VideoView',
    component: VideoView,
    props: true,
  },
  {
    path: '/m/watch/:videoId(.*)',
    name: 'RemoteControlView',
    component: RemoteControlView,
    props: true,
    meta: { layout: 'FullScreenLayout', unauthorized: true },
  },
  {
    path: '/upload',
    name: 'VideoUpload',
    component: VideoUpload,
    props: true,
  },
  {
    path: '/edit/:id(.*)',
    name: 'AnnotationTool',
    component: AnnotationTool,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { unauthorized: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  next();
  // if (to.meta.unauthorized === true) {
  //   next();
  //   return;
  // }

  // const user = auth.currentUser;
  // if (!user) {
  //   next('/signin');
  // } else {
  //   next();
  // }
});

export default router;
