import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

const app = createApp(App);
app.use(router);
app.use(store);

app.config.globalProperties = {
  ...app.config.globalProperties,
  $http: axios.create({
    baseURL: process.env.BASE_URL + 'api/',
    validateStatus: null,
  }),
  baseURL: process.env.BASE_URL + 'api',
};

app.mount('#app');
