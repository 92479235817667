<template>
  <div class="wrapper">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Label</td>
          <td>
            <input class="label-input" type="text" v-model.lazy="label" placeholder="Label" @blur="labelUpdate" />
          </td>
        </tr>
        <tr>
          <td>StartTime</td>
          <td>
            <input class="label-input" type="text" v-model.lazy="startTime" placeholder="0" @blur="startTimeUpdate" />
          </td>
        </tr>
        <tr>
          <td>EndTime</td>
          <td>
            <input class="label-input" type="text" v-model.lazy="endTime" placeholder="0" @blur="endTimeUpdate" />
          </td>
        </tr>
        <tr>
          <td>Points</td>
          <td>
            <table>
              <tr>
                <th>X</th>
                <th>Y</th>
              </tr>
              <tr v-for="(point, idx) in points" :key="idx">
                <td>{{ parseInt(point[0]) }}</td>
                <td>{{ parseInt(point[1]) }}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr v-if="false">
          <td colspan="2" class="tabnav">
            <button @click="saveContent">Save</button>
          </td>
        </tr>
        <tr>
          <td>Contents(HTML)</td>
          <td>
            <textarea :disabled="contentsHTMLSaveInProgress" :class="contentsHTMLClasses" v-model="content"
              @blur="saveContent">
            </textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["videoId", "options", "property", "scale"],
  data() {
    return {
      label: "",
      startTime: 0,
      endTime: 0,
      content: "",
      simpleAvailable: false,
      imgsrc: null,
      linkto: null,
      contentsHTMLSaveInProgress: false,
    };
  },
  computed: {
    toJson() {
      return JSON.stringify(this.property, null, 2);
    },
    objType() {
      return this.property?.type ?? "";
    },
    points() {
      if (!this.property) return [];
      const scaledPoints = this.property.points.map((p) => {
        return [p[0] / this.scale, p[1] / this.scale];
      });
      return scaledPoints;
    },
    contentsHTMLClasses() {
      return "contents-html";
    },
  },
  emits: ["labelUpdate", "startTimeUpdate", "endTimeUpdate"],
  methods: {
    labelUpdate(e) {
      e.stopPropagation();
      this.$emit("labelUpdate", {
        id: this.property.groupId,
        value: this.label,
      });
    },
    startTimeUpdate(e) {
      e.stopPropagation();
      const newValue = Math.max(0, this.startTime);
      this.$emit("startTimeUpdate", {
        id: this.property.groupId,
        value: newValue,
      });
    },
    endTimeUpdate(e) {
      e.stopPropagation();
      const newValue = Math.max(this.startTime, this.endTime);

      this.$emit("endTimeUpdate", {
        id: this.property.groupId,
        value: newValue,
      });
    },

    async loadContent() {
      console.log(this.property.content);
      const res = await this.$http.get(
        `/video/${this.videoId}/annotation/${this.property.content}`
      );

      let htmlContent = "";

      if (res.status === 200) {
        htmlContent = res.data;
        this.content = htmlContent;
      } else if (res.status === 404) {
        this.content = `<a class="link-url" href="#" target="ysm-content">
  <img class="banner" src="#" alt=""/>
</a>`;
        await this.saveContent();
        await this.loadContent();
      }
    },

    async saveContent() {
      this.contentsHTMLSaveInProgress = true;
      const res = await this.$http.put(
        `/video/${this.videoId}/annotation/${this.property.content}`,
        {
          html: this.content,
        }
      );

      if (res.status === 200) {
        this.contentsHTMLSaveInProgress = false;
      }
    },
  },
  mounted() {
    if (this.property) {
      (async () => await this.loadContent())();
      this.label = this.property?.label ?? "";
      this.startTime = this.property?.startTime ?? 0;
      this.endTime = this.property?.endTime ?? 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: visible;
  margin: 0;
  padding: 0;

  .label-input {
    width: 100%;
    background-color: inherit;
    border: none;
    font-size: 1.2em;
  }

  table {
    width: 100%;
    color: #fff;

    thead>tr:first-child>th:first-child {
      border-radius: 15px 0 0 0;
    }

    thead>tr:first-child>th:last-child {
      border-radius: 0 15px 0 0;
    }

    tbody>tr:last-child>td:first-child {
      border-radius: 0 0 0 15px;
    }

    tbody>tr:last-child>td:last-child {
      border-radius: 0 0 15px 0;
    }

    th {
      background: #393939;
      border-bottom: 1px solid #666;
      padding: 7px;
    }

    td {
      border-right: 1px solid #666;
      background: #494949;
      border-bottom: 1px solid #666;
      padding: 7px;

      &.line-none {
        border-right: 0;
      }
    }
  }

  textarea.contents-html {
    width: 100%;
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: scroll;
  }

  .tabnav {
    button {
      border: 1px solid pink;
      background: #eee;
      padding: 10px;
    }
  }
}
</style>