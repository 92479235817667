import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
  state: {
    user: null,
    showAnnotation: true,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setShowAnnotation(state, value) {
      state.showAnnotation = value;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
