<template>
  <div
    @pointerdown="onPointerDown"
    @pointermove="onPointerMove"
    @pointerup="onPointerUp"
  >
    <video
      v-show="false"
      :src="src"
      :width="200"
      autoplay
      controls
      ref="originalVideoRef"
    />
    <div ref="projectedVideo" />
  </div>
</template>

<script>
import * as THREE from "three";

function degToRad(deg) {
  return (deg * Math.PI) / 180;
}

export default {
  props: ["src", "width", "height", "projection-mode", "current-time"],
  emits: ["resize", "mousedown", "mousemove", "mouseup"],
  data() {
    return {
      vLon: 0,
      vLat: 0,
      latRange: [-89, 89],
      lonRange: [-Infinity, Infinity],

      timerId: -1,
      isUserInteracting: false,
    };
  },
  computed: {
    lon: {
      get() {
        return this.vLon;
      },
      set(val) {
        this.vLon = Math.max(this.lonRange[0], Math.min(this.lonRange[1], val));
      },
    },
    lat: {
      get() {
        return this.vLat;
      },
      set(val) {
        this.vLat = Math.max(this.latRange[0], Math.min(this.latRange[1], val));
      },
    },
    distance: {
      get() {
        return 50;
      },
    },
    phi() {
      return degToRad(90 - this.lat);
    },
    theta() {
      return degToRad(this.projectionMode === "180" ? this.lon - 90 : this.lon);
    },
  },
  methods: {
    updateViewport() {
      if (!this.width || !this.height) return;
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.width / this.height,
        1,
        1100
      );

      // this.camera.aspect = this.width / this.height;
      // this.camera.updateProjectionMatrix();

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
    },
    updateProjectionMode() {
      switch (this.projectionMode.toUpperCase()) {
        case "180":
          // 180 equirectangular projection
          this.geometry = new THREE.SphereGeometry(
            500,
            60,
            40,
            0,
            Math.PI,
            0,
            Math.PI
          );
          // invert the geometry on the x-axis so that all of the faces point inward
          this.geometry.scale(-1, 1, 1);
          break;
        case "360-CUBE":
          this.geometry = new THREE.BoxGeometry(256, 256, 256);
          break;
        case "360":
        default:
          this.geometry = new THREE.SphereGeometry(
            500,
            60,
            40,
            0,
            Math.PI * 2,
            0,
            Math.PI
          );
          // invert the geometry on the x-axis so that all of the faces point inward
          this.geometry.scale(-1, 1, 1);
      }
    },
    updateSrc() {
      const video = this.$refs.originalVideoRef;

      this.scene = new THREE.Scene();

      this.texture = new THREE.VideoTexture(video);
      this.texture.minFilter = THREE.LinearFilter;

      if (this.projectionMode === "180" || this.projectionMode === "360") {
        this.material = new THREE.MeshBasicMaterial({
          map: this.texture,
        });
        const mesh = new THREE.Mesh(this.geometry, this.material);

        this.scene.add(mesh);
      } else if (this.projectionMode.toUpperCase() === "360-CUBE") {
        this.material = new THREE.MeshBasicMaterial({
          map: this.texture,
          side: THREE.BackSide,
        });

        // prettier-ignore
        const uvs = [
          2/3,2/2, 1/3,2/2, 2/3,1/2, 1/3,1/2, // Front
          2/3,0/2, 2/3,1/2, 1/3,0/2, 1/3,1/2, // Back
          3/3,1/2, 2/3,1/2, 3/3,0/2, 2/3,0/2, // Top
          0/3,0/2, 1/3,0/2, 0/3,1/2, 1/3,1/2, // Down
          3/3,2/2, 2/3,2/2, 3/3,1/2, 2/3,1/2, // Right
          1/3,2/2, 0/3,2/2, 1/3,1/2, 0/3,1/2, // Left
        ];

        this.geometry.setAttribute(
          "uv",
          new THREE.BufferAttribute(new Float32Array(uvs), 2)
        );

        const mesh = new THREE.Mesh(this.geometry, this.material);
        mesh.position.set(0, 0, 0);
        mesh.rotation.y = -Math.PI;

        this.scene.add(mesh);
      } else {
        console.error("Unknown projection:", this.projectionMode);
      }
    },
    updateFrame() {
      if (!this.camera || !this.renderer) return;
      this.camera.position.x =
        this.distance * Math.sin(this.phi) * Math.cos(this.theta);
      this.camera.position.y = this.distance * Math.cos(this.phi);
      this.camera.position.z =
        this.distance * Math.sin(this.phi) * Math.sin(this.theta);

      this.camera.lookAt(0, 0, 0);

      this.renderer.render(this.scene, this.camera);
    },
    onPointerDown(event) {
      this.isUserInteracting = true;

      this.onPointerDownPointerX = event.clientX;
      this.onPointerDownPointerY = event.clientY;

      this.onPointerDownLon = this.lon;
      this.onPointerDownLat = this.lat;
    },
    onPointerMove(event) {
      if (this.isUserInteracting === true) {
        this.lon =
          (this.onPointerDownPointerX - event.clientX) * 0.1 +
          this.onPointerDownLon;
        this.lat =
          (this.onPointerDownPointerY - event.clientY) * 0.1 +
          this.onPointerDownLat;
      }
    },
    onPointerUp() {
      this.isUserInteracting = false;
    },
  },
  watch: {
    width: function () {
      this.updateViewport();
    },
    height: function () {
      this.updateViewport();
    },
    projectionMode: function () {
      this.updateProjectionMode();
    },
    src: function () {
      this.updateSrc();
    },
    currentTime: function () {
      const video = this.$refs.originalVideoRef;
      if (Math.abs(video.currentTime - this.currentTime) > 0.1) {
        video.currentTime = this.currentTime;
        if (video.paused) {
          video.play();
        }
      }
    },
  },
  created() {
    this.renderer = new THREE.WebGLRenderer();

    this.updateViewport();
    this.updateProjectionMode();
  },
  mounted() {
    this.updateSrc();

    const target = this.$refs.projectedVideo;
    target.innerHTML = "";
    target.appendChild(this.renderer.domElement);

    // init animateframe
    if (this.timerId >= 0) {
      cancelAnimationFrame(this.timerId);
    }

    const animate = () => {
      this.updateFrame();
      this.timerId = requestAnimationFrame(animate);
    };

    this.timerId = requestAnimationFrame(animate);
  },
  beforeUnmount() {
    if (this.timerId >= 0) {
      cancelAnimationFrame(this.timerId);
    }
  },
};
</script>

<style scoped>
</style>