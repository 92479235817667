<template>
  <div class="wrapper">
    <table>
      <thead><tr><th>ID</th><th>Name</th><th>Type</th><th>S</th><th>E</th></tr></thead>
      <tbody>
        <tr v-for="(shape, key) in sortedShapes" :key="key" @click="selectObject(key)">
          <td>{{ shape.groupId }}</td>
          <td>{{ shape.label }}</td>
          <td>{{ shape.type }}</td>
          <td>{{ Math.floor(shape.startTime) }}</td>
          <td>{{ Math.ceil(shape.endTime) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['shapes'],
  emits: ['selectObject'],
  computed: {
    sortedShapes() {
      let sorted = Array.from(this.shapes);
      sorted = sorted.map((o, i) => Object.assign(o, { reverseRef: i} ));
      return sorted.sort((a, b) => {
        return a.startTime - b.startTime
      })
    }
  },
  methods: {
    selectObject(key) {
      this.$emit('selectObject', this.sortedShapes[key].reverseRef);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: visible;
  margin: 0;
  padding: 0;

  table {
    width:100%;
    color: #fff;

    thead > tr:first-child > th:first-child {
      border-radius: 15px 0 0 0;
    }

    thead > tr:first-child > th:last-child {
      border-radius: 0 15px 0 0;
    }

    tbody > tr:last-child > td:first-child {
      border-radius: 0 0 0 15px;
    }

    tbody > tr:last-child > td:last-child {
      border-radius: 0 0 15px 0;
    }

    tbody > tr {
      th, td {
        cursor: pointer;
      }
    }

    th {
      background:#393939;
      border-bottom:1px solid #666;
      padding:7px;
    }

    td {
      border-right:1px solid #666;
      background:#494949;
      border-bottom:1px solid #666;
      padding:7px;

      &.line-none {
        border-right:0;
      }
    }

  }
}

</style>