<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import NavbarLayout from "@/layouts/NavbarLayout.vue";
import FullScreenLayout from "@/layouts/FullScreenLayout.vue";

export default {
  name: "TheLayout",
  components: {
    NavbarLayout,
    FullScreenLayout,
  },
  computed: {
    layout() {
      return this.$router.currentRoute.value.meta.layout || "NavbarLayout";
    },
  },
};
</script>
