<template>
  <div class="wrapper">
    <table>
      <thead>
        <tr>
          <th colspan="2">Options</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>OverlayPosition</td>
          <td>
            <select :value="overlayPosition" @change="onOverlayPositionChanged">
              <option value="float">float</option>
              <option value="fixed">fixed</option>
            </select>
          </td>
        </tr>
        <tr v-if="overlayPosition === 'fixed'">
          <td>Style</td>
          <td>
            <input v-model="overlayStyle" @blur="onOverlayStyleChanged" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["videoId", "options"],
  data() {
    return {};
  },
  computed: {
    overlayPosition() {
      return this.options?.overlayPosition || "float";
    },
    overlayStyle: {
      get() {
        return (
          this.options?.overlayStyle ||
          "bottom: 10px; left: 0; width: 100%; height: 150px;"
        );
      },
    },
  },
  emits: ["overlayPositionChanged"],
  methods: {
    onOverlayPositionChanged(e) {
      this.$emit("onOverlayPositionChanged", e.target.value);
    },
    onOverlayStyleChanged(e) {
      this.$emit("onOverlayStyleChanged", e.target.value);
    },
  },
  mounted() {},

  afterUpdate() {
    console.log(this.options);
    if (this.options?.overlayStyle) {
      this.overlayStyle = this.options.overlayStyle;
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: auto;
  overflow-y: visible;
  margin: 0;
  padding: 0;

  table {
    width: 100%;
    color: #fff;

    thead > tr:first-child > th:first-child {
      border-radius: 15px 0 0 0;
    }

    thead > tr:first-child > th:last-child {
      border-radius: 0 15px 0 0;
    }

    tbody > tr:last-child > td:first-child {
      border-radius: 0 0 0 15px;
    }

    tbody > tr:last-child > td:last-child {
      border-radius: 0 0 15px 0;
    }

    th {
      background: #393939;
      border-bottom: 1px solid #666;
      padding: 7px;
    }

    td {
      border-right: 1px solid #666;
      background: #494949;
      border-bottom: 1px solid #666;
      padding: 7px;

      &.line-none {
        border-right: 0;
      }
    }
  }

  textarea.bar-style {
    width: 100%;
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: scroll;
  }
}
</style>