<template>
  <div class="container">
    <Tile :items="items" style="grid-area: main" />
    <aside style="grid-area: aside">
      <div class="qr-container">
        <h2>Sync your Second screen with this qr-code</h2>
        <a :href="remotecontrolLink" target="remotecontrol">
          <canvas ref="qrcode" width="200px" height="200px" />
        </a>
      </div>
    </aside>
  </div>
</template>

<script>
import Tile from "@/components/Tile.vue"; // @ is an alias to /src
import { ref, getCurrentInstance, onMounted } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";

import QRCode from "qrcode";

export default {
  components: {
    Tile,
  },

  setup(props, context) {
    const qrcode = ref(null);
    const items = ref([]);
    const remotecontrolLink = ref("");
    const { proxy } = getCurrentInstance();
    const route = useRoute();

    const getItems = async (to) => {
      if (!to) to = route;
      const itemList = (
        await proxy.$http.get("/video", {
          params: to.query,
        })
      ).data;
      const newItems = [];

      for (const item of itemList) {
        const baseURL = `${proxy.baseURL}/resources/videos/${item.owner}/${item.title}`;
        newItems.push({
          id: `${item.owner}/${item.title}`,
          thumbnail: `${baseURL}/thumbnail1.jpg`,
          animatedThumbnail: `${baseURL}/thumbnail2.png`,
          title: `${item.title}`,
          owner: `${item.owner}`,
          views: item.views,
          likes: item.likes,
          dateUploaded: new Date(item.createdAt),
        });
      }

      items.value = newItems;
    };

    onBeforeRouteUpdate(async (to, from) => {
      console.log("Trigger onBeforeRouteUpdate");
      await getItems(to);
    });

    onMounted(async () => {
      await getItems();

      const { protocol, host } = new URL(window.location);
      const deviceId = window.localStorage.getItem("deviceId");
      remotecontrolLink.value = `${protocol}//${host}/m?room=${deviceId}`;
      QRCode.toCanvas(qrcode.value, remotecontrolLink.value);
    });

    return {
      qrcode,
      items,
      remotecontrolLink,
    };
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: auto 250px;
  grid-template-rows: auto;
  grid-template-areas: "main aside";
}

.qr-container {
  display: flex;
  align-items: center;
}
</style>