<template>
  <div class="container">
    <LoginModal :visible="loginModalVisible" @loginSuccess="loginSuccess" />
  </div>
</template>

<script>
import _ from "lodash";
import LoginModal from "@/components/LoginModal.vue";
import { socket } from "@/service/websocket";

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      deviceId: null,
      loginModalVisible: false,
    };
  },
  methods: {
    async logout() {
      await this.$http.get("/logout");
      this.$store.commit("setUser", null);
      this.$router.push("/m");
    },
    loginSuccess() {
      this.loginModalVisible = false;
    },
  },
  mounted() {
    this.deviceId = window.localStorage.getItem("deviceId");
    const { searchParams } = new URL(window.location);
    const roomId = searchParams.get("room") || this.deviceId;

    socket.emit("join", {
      from: this.deviceId,
      room: roomId,
    });

    socket.on("sync", (msg) => {
      const videoId = msg.videoId;

      if (videoId !== this.videoId) {
        this.$router.push("/m/watch/" + videoId);
      }
    });
  },

  beforeUnmount() {
    clearInterval(this.pingTimer);
  },
};
</script>

<style scoped>
.container {
  display: block;
  height: 100vh;
  overflow-y: scroll;
}
</style>