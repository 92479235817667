<template>
  <div class="seeker-bar">
    <video
      class="hover-preview"
      ref="hoverPreviewElement"
      muted
      v-bind:style="hoverPreviewStyle"
      v-show="seekerOnHover"
    >
      <source :src="`${baseURL}api/resources/videos/${src}/video.mp4`" />
    </video>
    <svg
      version="1.1"
      baseProfile="full"
      width="100%"
      height="30px"
      xmlns="http://www.w3.org/2000/svg"
      style="margin-top: 115px"
    >
      <g
        @mouseenter="seekerOnHover = true"
        @mouseleave="seekerOnHover = false"
        @mousemove="adjustPreviewPosition"
      >
        <rect
          x="0"
          y="7"
          width="100%"
          height="5"
          fill="rgba(255,255,255,0.7)"
          stroke="rgba(127,127,127,0.7)"
        />
        <!-- seeker expension -->
        <rect
          @click="seek"
          @mousemove="dragSeek"
          x="0"
          y="0"
          width="100%"
          height="30"
          fill="transparent"
          stroke="transparent"
          class="clickable"
        />
        <g
          v-for="(bullet, i) in annotationBullets"
          :key="i"
          @mouseleave="hoveredBullet = -1"
          class="non-clickable"
        >
          <circle
            :cx="bullet.x"
            :cy="10"
            r="5"
            fill="#fff"
            @mouseenter="hoveredBullet = i"
            @click="jumpTo(i)"
          />
          <!-- <image v-bind="bullet" v-show="hoveredBullet===i"/> -->
        </g>
        <circle :cx="currentTimeBulletPosition" cy="10" r="10" fill="#e46262" />
      </g>
    </svg>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "seeker-bar",
  props: ["src", "annotation", "currentTime", "duration", "width"],
  emits: ["seek"],

  data() {
    return {
      baseURL: process.env.BASE_URL,
      hoveredBullet: -1,
      seekerOnHover: false,
      hoverPreviewStyle: {
        left: "0",
      },
      hoverPreviewTime: 0,
    };
  },

  computed: {
    annotationBullets() {
      const { header, metadataObject } = this.annotation;

      if (!header || !metadataObject) return;

      const bullets = [];

      if (header.version >= 2) {
        for (const annotation of metadataObject) {
          const position =
            ((annotation.timeRange?.[0] || NaN) / this.duration) * this.width;

          if (!_.isFinite(position)) break;

          bullets.push({
            x: position,
            href: `${this.baseURL}api/resources/videos/${header.owner}/${header.id}/annotation/${annotation.img}`,
            width: 150,
            height: 120,
          });
        }
      } else {
        // DEPRECATED
      }

      return bullets;
    },

    currentTimeBulletPosition() {
      const pos = (this.currentTime / this.duration) * this.width;

      return _.isFinite(pos) ? pos : 0;
    },
  },

  methods: {
    seek(e) {
      const { x, width } = e.target.getBoundingClientRect();
      const p = (e.clientX - x) / (width - x);

      this.$emit("seek", this.duration * p);
    },

    dragSeek(e) {
      const { x, width } = e.target.getBoundingClientRect();
      const p = (e.clientX - x) / (width - x);
      const previewTime = this.duration * p;

      if (e.buttons === 1) {
        this.$emit("seek", previewTime);
      }
    },

    adjustPreviewPosition(e) {
      const { x, width } = e.target.getBoundingClientRect();
      const p = (e.clientX - x) / (width - x);
      const previewTime = this.duration * p;
      this.hoverPreviewTime = previewTime;
      this.hoverPreviewStyle = {
        left: e.clientX - x + "px",
      };
    },

    jumpTo(i) {
      this.$emit(
        "seek",
        (this.duration * this.annotationBullets[i].x) / this.width
      );
    },
  },

  watch: {
    hoverPreviewTime: _.debounce(function (previewTime) {
      this.$refs.hoverPreviewElement.currentTime = previewTime;
    }, 100),
  },
};
</script>

<style lang="scss" scoped>
.seeker-bar {
  pointer-events: none;

  .clickable {
    pointer-events: auto;
  }

  .non-clickable {
    pointer-events: none;
  }

  .hover-preview {
    position: absolute;
    width: 180px;
    height: 100px;
    border: 1px solid #000;
  }
}
</style>