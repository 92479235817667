<template>
  <div id="app">
    <the-layout>
      <router-view />
    </the-layout>
  </div>
</template>

<script>
import { nanoid } from "nanoid/non-secure";
import TheLayout from "@/layouts/TheLayout.vue";

export default {
  name: "App",
  components: {
    TheLayout,
  },
  created() {
    let deviceId = window.localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = nanoid();
      window.localStorage.setItem("deviceId", deviceId);
    }
  },
};
</script>

<style lang="scss">
@import "@/common.scss";
@import "@/variables.scss";
</style>
