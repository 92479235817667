<template>
  <div class="modal-wrapper">
    <div class="modal-body" v-if="showModal">
      <div class="progress-window">
        <h4>
          Annotating: <span>{{ autoAnnotationProgress * 100 }}%</span>
        </h4>
      </div>
    </div>
    <div class="component-wrapper">
      <div class="toolbar">
        <button class="btn" id="back" title="Back" @click="back">
          <i class="fas fa-arrow-left" />
        </button>
        <button :class="{ active: this.mode === 'add-rectangle', btn: true }" @click="addRect" title="Rectangle">
          <i class="fas fa-vector-square"></i>
        </button>
        <div>
          <button class="btn2" v-show="videoPaused" @click="playPause()">
            <i class="fas fa-play" />
          </button>
          <button class="btn2" v-show="!videoPaused" @click="playPause()">
            <i class="fas fa-pause" />
          </button>
        </div>
        <button class="btn" id="show-annotation" title="Show Annotation" @click="showAnnotation">
          <i class="fas fa-file-code" />
        </button>
        <span v-if="false" class="debug">
          Mode: {{ mode }}, selectedObject: {{ selectedObjKey }}
        </span>
        <button class="btn2" style="color: cyan; border-radius: 4px; border: 1px dotted cyan" @click="resetAnnotation">
          <i class="fa fa-sync-alt" />
        </button>
        <button class="btn2" style="color: #f33; border-radius: 4px; border: 1px dotted red" @click="deleteVideo">
          <i class="fa fa-trash" />
        </button>
      </div>
      <div class="video-area">
        <div class="canvas-wrapper" ref="canvasWrapper">
          <video ref="videoElement" muted autoplay :width="width" :height="height"
            style="position: absolute; top: 0; left: 0" @loadedmetadata="onLoadedMetadata" @timeupdate="onTimeUpdate"
            @play="onPlay" @pause="onPause">
            <source :src="`${baseURL}api/resources/videos/${src}/video.mp4`" />
          </video>
          <svg version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height"
            style="z-index: 100" ref="canvasTool" id="canvas-tool" tabindex="0"
            @mousedown="canvasMouseDownActionHandler" @mouseup="canvasMouseUpActionHandler" @mousemove="handlePointDrag"
            @keyup.delete="deleteSelectedObject" @keypress.space="playPause">
            <g v-for="(obj, key) in shapes" :key="'shape-' + key">
              <g v-if="obj.startTime <= currentTime && obj.endTime >= currentTime
      " class="shapes">
                <rect v-if="obj.type === 'rectangle'" v-bind="getRectangleData(obj.points)"
                  @click="selectObject(key)" />
                <image v-if="obj.img" :href="`${baseURL}api/resources/videos/${src}/annotation/${obj.img}`"
                  v-bind="getRectangleData(obj.points)" @click="selectObject(key)" />
              </g>
            </g>
            <g v-for="(obj, key) in shapes" :key="'label-' + key">
              <!-- Labels -->
              <g v-if="obj.startTime <= currentTime && obj.endTime >= currentTime
      ">
                <g v-if="obj.type !== 'deleted'" @click="selectObject(key)" class="labels">
                  <rect v-bind="getLabelData(obj)" />
                  <text class="annotation-tag" v-bind="getLabelData(obj)">
                    {{ obj.label }}
                  </text>
                </g>
              </g>
            </g>
            <g v-if="selectedObjKey >= 0">
              <!-- Controls -->
              <circle class="control" v-for="(p, index) in shapes[selectedObjKey].points" :key="index" :cx="p[0]"
                :cy="p[1]" :r="5" @mousedown="handlePointDragStart(p, selectedObjKey, index)"
                @mouseup="handlePointDragStop(p, selectedObjKey, index)" />
            </g>
          </svg>
        </div>
        <seeker-bar class="seeker-bar" @seek="seek" :src="src" :annotation="annotationData" :currentTime="currentTime"
          :duration="duration" :width="width" />
        <!-- currentTime/duretion indicator -->
        <div class="time-indicator">
          {{ new Date(currentTime * 1000).toISOString().substr(11, 8) }} /
          {{ new Date(duration * 1000).toISOString().substr(11, 8) }}
        </div>
      </div>
      <div class="right-pane">
        <option-view class="option-view" :videoId="src" :options="options"
          @onOverlayPositionChanged="onOverlayPositionChanged" @onOverlayStyleChanged="onOverlayStyleChanged">
        </option-view>
        <property-view class="property-view" :key="selectedObjKey" :property="selectedObjectProperty" :videoId="src"
          :scale="scale" @labelUpdate="selectedObjectLabelUpdate" @startTimeUpdate="selectedObjectStartTimeUpdate"
          @endTimeUpdate="selectedObjectEndTimeUpdate"></property-view>
        <label-list-view class="label-list" v-show="true" :shapes="shapes" @selectObject="gotoObject" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */

import _ from "lodash";
import { nanoid } from "nanoid/non-secure";

import PropertyView from "./PropertyView";
import OptionView from "./OptionView.vue";
import LabelListView from "./LabelListView.vue";
import SeekerBar from "./SeekerBar.vue";

export default {
  name: "annotation-canvas",
  components: {
    OptionView,
    PropertyView,
    LabelListView,
    SeekerBar,
  },
  props: ["videoId"],
  data() {
    return {
      baseURL: process.env.BASE_URL,
      src: null,
      annotationData: {},

      width: 800,
      height: 600,
      scale: 1.0,
      shapes: [],
      currentHandle: null,
      selectedObjKey: -1,
      selectedObjectProperty: null,
      mode: "idle",

      videoPaused: false,
      currentTime: 0,
      duration: 0,

      showModal: false,

      autoAnnotationProgress: 0,
    };
  },
  computed: {
    options() {
      return this.annotationData?.header ?? {};
    },
  },
  methods: {
    /// Utility
    getCurrentMousePosition() {
      const anchor = this.$refs.canvasTool.getBoundingClientRect();
      return [
        window.event.pageX - anchor.left,
        window.event.pageY - anchor.top,
      ];
    },
    getDistance(p1, p2) {
      return Math.sqrt(Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2));
    },
    getRectangleData(points) {
      const left = Math.min(points[0][0], points[1][0]);
      const top = Math.min(points[0][1], points[1][1]);
      return {
        x: left,
        y: top,
        width: Math.abs(points[1][0] - points[0][0]),
        height: Math.abs(points[1][1] - points[0][1]),
      };
    },
    getLabelData(obj) {
      const points = obj.points;
      const left = points[0][0]; //_.min(points.map((p) => p[0]));
      const top = points[0][1]; //_.min(points.map((p) => p[1]));
      const labelLength = obj.label?.length || 0;
      const height = 16;
      return {
        x: left,
        y: top - height,
        dy: height - 2,
        width: labelLength * 7,
        height: height,
        fill: "blue",
        stroke: "blue",
      };
    },

    selectObject(key) {
      this.selectedObjKey = key;
      const groupId = this.shapes[key].groupId;

      const objects = _(this.shapes)
        .filter((o) => o.groupId === groupId)
        .orderBy((o) => o.id)
        .value();

      const selectedObjectProperty = {
        ...objects[0],
        ...{
          endTime: objects.at(-1).endTime,
        },
      };
      this.selectedObjectProperty = selectedObjectProperty;
      this.currentHandle = null;
      this.mode = "idle";
    },
    gotoObject(key) {
      this.selectObject(key);

      this.seekTo(this.shapes[key].startTime + 0.1);
    },
    selectedObjectLabelUpdate(event) {
      const groupId = event.id;

      this.shapes = this.shapes.map((o) => {
        if (o.groupId === groupId) {
          o.label = event.value;
        }

        return o;
      });
    },
    selectedObjectStartTimeUpdate(event) {
      const groupId = event.id;

      const objects = _(this.shapes)
        .filter((o) => o.groupId === groupId)
        .orderBy((o) => o.id)
        .value();

      objects[0].startTime = parseFloat(event.value);
    },
    selectedObjectEndTimeUpdate(event) {
      const groupId = event.id;

      const objects = _(this.shapes)
        .filter((o) => o.groupId === groupId)
        .orderBy((o) => o.id)
        .value();

      objects[1].startTime = parseFloat(event.value);
      objects[1].endTime = parseFloat(event.value);
    },
    onOverlayPositionChanged(position) {
      this.annotationData.header = {
        ...this.annotationData.header,
        overlayPosition: position,
      };
    },
    onOverlayStyleChanged(style) {
      this.annotationData.header = {
        ...this.annotationData.header,
        overlayStyle: style,
      };
    },

    /// Back
    back() {
      this.$router.push("/watch/" + this.videoId);
    },

    // Video Controls
    onLoadedMetadata() {
      const { width: componentWidth, height: componentHeight } = this;
      const { videoWidth, videoHeight } = this.$refs.videoElement;

      console.log({ componentWidth, componentHeight, videoWidth, videoHeight });

      if (videoWidth > componentWidth) {
        this.scale = componentWidth / videoWidth;
        this.height = videoHeight * this.scale;
      } else if (videoHeight > componentHeight) {
        this.scale = componentHeight / videoHeight;
        this.width = videoWidth * this.scale;
      } else {
        this.scale = componentWidth / videoWidth;
        this.height = videoHeight * this.scale;
      }

      console.log(`scale: ${this.scale}`);

      (async () => await this.load())();
    },
    onTimeUpdate() {
      const el = this.$refs?.videoElement;
      this.currentTime = el?.currentTime ?? 0;
      this.duration = el?.duration ?? 0;
    },
    onPlay() {
      this.videoPaused = false;
    },
    onPause() {
      this.videoPaused = true;
    },
    playPause() {
      const el = this.$refs?.videoElement;
      if (el.paused) {
        el.play();
        this.selectedObjKey = -1;
      } else {
        el.pause();
      }
    },
    play() {
      const el = this.$refs?.videoElement;
      el?.play();
    },
    pause() {
      const el = this.$refs?.videoElement;
      el?.pause();
    },
    seek(time) {
      const el = this.$refs?.videoElement;
      if (_.isFinite(el.duration)) {
        this.currentTime = Math.min(Math.max(time, 0), el.duration);
        el.currentTime = this.currentTime;
      }
    },

    /// Key Handler
    async deleteSelectedObject() {
      if (this.selectedObjKey >= 0) {
        const groupId = this.shapes[this.selectedObjKey].groupId;

        this.shapes = this.shapes.filter((o) => {
          return o.groupId !== groupId;
        });
        this.selectedObjKey = -1;
      }

      await this.save();
    },

    /// Handle Controller
    handlePointDragStart(p, key, index) {
      if (this.mode === "idle") {
        this.mode = "drag-handle";
        this.currentHandle = { key, index };
      }
    },
    handlePointDrag() {
      if (!this.currentHandle) return;

      const { key, index } = this.currentHandle;

      const groupId = this.shapes[key].groupId;
      // Workaround: Edit a whole groupId objects
      // WARNING: 땜빵으로 고침. 3개 이상의 애니메이션 키프레임 데이터 존재시 객체 파괴함
      const objs = this.shapes.filter((o) => o.groupId === groupId);

      for (const obj of objs) {
        obj.points[index] = this.getCurrentMousePosition();
      }
    },
    async handlePointDragStop(p, key, index) {
      this.mode = "idle";
      this.currentHandle = null;

      const groupId = this.shapes[key].groupId;
      const objs = this.shapes.filter((o) => o.groupId === groupId);

      for (const obj of objs) {
        // 바운딩 박스 객체의 경우 좌상단-우하단 포인트 정렬을 유지함
        if (obj.points.length === 2) {
          const p1 = obj.points[0];
          const p2 = obj.points[1];
          if (p1[0] > p2[0]) {
            [p1[0], p2[0]] = [p2[0], p1[0]]; // Swap
          }
          if (p1[1] > p2[1]) {
            [p1[1], p2[1]] = [p2[1], p1[1]]; // Swap
          }
        }
      }

      await this.save();
    },

    async addShapeInteractive(type, points) {
      const id = nanoid();
      const name = "" + type[0] + (Date.now() % 1000);
      const obj1 = {
        groupId: id,
        id: `${id}[000]`,
        name: name,
        img: "",
        content: `${id}.html`,
        type: type,
        label: name,
        startTime: this.currentTime - 0.2,
        endTime: this.currentTime + 1.0,
        points: points,
      };
      this.shapes = [...this.shapes, obj1];
      const obj2 = { ...obj1 };
      obj2.id = `${id}[001]`;
      obj2.startTime = obj2.endTime;
      this.shapes = [...this.shapes, obj2];

      this.selectedObjKey = this.shapes.length - 1;
      this.currentHandle = {
        key: this.shapes.length - 1,
        index: points.length - 1,
      };
      this.mode = "drag-handle";

      await this.save();
    },

    canvasMouseDownActionHandler() {
      const p = this.getCurrentMousePosition();
      this.pause();

      switch (this.mode) {
        case "idle":
          this.selectedObjKey = -1;
          this.selectedObjectProperty = null;
          return;
        case "drag-handle":
          return;
        case "add-rectangle":
          this.addShapeInteractive("rectangle", [p, p]);
          return;
      }
    },

    canvasMouseUpActionHandler() {
      switch (this.mode) {
        case "idle":
          this.selectedObjKey = -1;
          this.selectedObjectProperty = null;
          return;
        case "drag-handle":
          this.mode = "idle";
          return;
      }
    },

    /// Button handlers
    addRect() {
      if (this.mode === "idle") {
        this.mode = "add-rectangle";
      }
    },
    async resetAnnotation() {
      const confirm = window.confirm(
        "This will reset all annotations. Are you sure?"
      );

      if (confirm) {
        await this.$http.put(`/video/${this.videoId}/auto-annotation`);

        this.showModal = true;
        console.log(this.showModal);

        // eslint-disable-next-line no-constant-condition
        const progressHandler = async () => {
          const res = await this.$http.get(
            `/video/${this.videoId}/auto-annotation`
          );
          console.log(this.showModal);
          console.log(res.data);
          this.autoAnnotationProgress = res.data.progress;

          if (_.includes(["ready", "pending", "working"], res.data.status)) {
            setTimeout(progressHandler, 1000);
          } else {
            this.showModal = false;
            this.load();
          }
        };

        setTimeout(progressHandler, 0);
      }
    },
    deleteVideo() {
      const confirm = window.confirm(`This will delete ${this.src}. You sure?`);

      if (confirm) {
        this.$http.delete(`/video/${this.videoId}`);
        this.$router.push("/");
      }
    },

    /// Import/export
    async save() {
      const metadataObject = [];
      const scale = this.scale;
      const shapes = this.shapes;
      const objects = _(shapes)
        .filter((o) => o.type !== "deleted")
        .groupBy((o) => o.groupId)
        .value();

      for (const groupId of Object.keys(objects)) {
        const seq = _.orderBy(objects[groupId], (o) => o.startTime);
        const newAnnotation = {
          id: groupId,
          name: seq[0].label,
          img: seq[0].img,
          content: seq[0].content,
          timeRange: [_.first(seq).startTime, _.last(seq).endTime],
        };

        newAnnotation.bbox = seq.map((b) => {
          return [
            b.startTime,
            [b.points[0][0] / this.width, b.points[0][1] / this.height],
            [b.points[1][0] / this.width, b.points[1][1] / this.height],
          ];
        });

        metadataObject.push(newAnnotation);
      }

      const url = `video/${this.src}/annotations`;

      const data = this.annotationData;
      data.metadataObject = metadataObject;

      const res = await this.$http.put(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },

    async load() {
      const url = `resources/videos/${this.src}/annotation.json`;
      const res = await this.$http.get(url);

      if (res.status >= 400) {
        return;
      }
      this.annotationData = res.data || {
        header: {},
      };

      this.shapes = [];
      for (const obj of this.annotationData?.metadataObject ?? []) {
        const bboxList = obj.bbox;

        for (const i in bboxList) {
          const bbox = bboxList[i];

          const newShape = {
            groupId: obj.id,
            id:
              obj.id + "[" + (1000 + parseInt(i)).toString().substr(1, 3) + "]",
            name: obj.name,
            img: obj.img,
            content: obj.content,
            label: obj.name,
            points: [
              [bbox[1][0] * this.width, bbox[1][1] * this.height],
              [bbox[2][0] * this.width, bbox[2][1] * this.height],
            ],
            startTime: bbox[0],
            endTime:
              parseInt(i) + 1 < bboxList.length
                ? bboxList[parseInt(i) + 1][0]
                : bbox[0],
          };

          if (!newShape.type) {
            newShape.type = "rectangle";
          }
          this.shapes.push(newShape);
        }
      }
    },

    showAnnotation() {
      window.open(
        `${this.baseURL}api/resources/videos/${this.src}/annotation.json`,
        "annotation-view"
      );
    },
  },

  mounted() {
    this.width = Math.max(640, this.$refs.canvasWrapper.clientWidth);
    this.height = Math.max(480, this.$refs.canvasWrapper.clientHeight);
    this.$refs.videoElement.disablePictureInPicture = true;

    this.src = this.videoId;
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.modal-wrapper {
  width: 100vw;
  height: calc(100vh - 200px);
  position: relative;
  z-index: 50;
}

.modal-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* display: hidden; */
  z-index: 500;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;

  .progress-window {
    position: fixed;
    top: 50%;
    left: calc(50% - 150px);
    width: 300px;
    height: 4rem;
    border-radius: 10px;
    border: 3px double pink;
    background: white;
    text-align: center;
  }
}

.component-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 0;
  grid-template-columns: 1fr 500px;
  grid-template-rows: 44px 1fr;
  grid-template-areas:
    "header header"
    "video-area right-pane";
  background: #5c5c5c;
  margin: 0;
  padding: 0;
  z-index: 50;
}

.toolbar {
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 40px;

  width: 100%;
  height: 44px;
  background-color: #2f2f2f;

  .btn {
    font-size: 32px;
    background-color: inherit;
    color: #fff;
    border: none;
    padding: 0 15px;

    &:nth-child(1) {
      padding: 0 50px 0 0;
    }
  }
}

.loading-box {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #fff;
  align-items: center;
  margin: 0 auto 0 510px;
}

.video-area {
  grid-area: video-area;
  position: relative;
}

.canvas-wrapper {
  grid-area: video;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;

  border: 1px solid #000;
  font-family: "Arial", "Sans-serif";

  img,
  svg,
  video {
    position: relative;
    top: 0;
    left: 0;
    margin: auto;
    border: 1px solid #000;
    overflow: hidden;
  }

  g {
    fill: none;
    stroke: #000;
    stroke-width: 3;

    &.shapes {
      stroke: rgb(12, 23, 175);
    }
  }

  .control {
    fill: rgba(255, 255, 255, 1);
    stroke: #000;
    stroke-width: 1;
  }

  .polygon-drawing {
    stroke: #f33;
  }

  .annotation-tag {
    font-family: "monospace";
    // font-stretch: ultra-condensed;
    cursor: pointer;
    font-size: 14px;
    stroke: none;
    fill: white;
    stroke-width: 1;
    font-weight: normal;
  }
}

.right-pane {
  grid-area: right-pane;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 280px);
}

.option-view {
  height: auto;
  overflow-y: visible;
}

.property-view {
  height: auto;
  overflow-y: visible;
}

.label-list {
  visibility: hidden;
  height: auto;
  overflow-y: scroll;
}

.btn {
  img {
    width: 24px;
    height: 24px;
  }

  &.active {
    background-color: red;
  }
}

.btn2 {
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  color: #eee;
  cursor: pointer;
}

.seeker-bar {
  grid-area: video;
  position: relative;
  z-index: 200;
  width: calc(100% - 5px);
  left: 5px;
  height: 130px;
  transform: translateY(-110px);
}

.time-indicator {
  grid-area: video;
  position: relative;
  z-index: 199;
  margin-left: calc(100% - 10rem);
  padding: 2px 5px;
  height: 20px;
  border-radius: 5px;
  background-color: #333;
  color: white;
  font-size: 16px;
  text-align: center;
  transform: translateY(-150px);
}

.debug {
  color: white;
}
</style>