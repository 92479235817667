<template>
  <li class="card">
    <router-link :to="linkTo">
      <div
        class="thumbnail-container"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <img class="thumbnail" :src="thumbnail" :alt="title" />
      </div>
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="author">
        <span>{{ author }}</span>
      </div>
      <div class="info">
        <span>▷</span><span class="view-count">{{ views }} </span>
        <span>&nbsp;</span>
        <span style="color: pink">♡</span
        ><span class="like-count">{{ likes }} </span>
        <span> · </span>
        <span class="date-uploaded">{{ dateUploaded }}</span>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    linkTo() {
      return `/watch/${this.item.id}`;
    },
    dateUploaded() {
      const date = this.item.dateUploaded;
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();

      mm = (mm > 9 ? "" : "0") + mm;
      dd = (dd > 9 ? "" : "0") + dd;

      return `${yyyy}.${mm}.${dd}`;
    },

    thumbnail() {
      return this.hover ? this.item.animatedThumbnail : this.item.thumbnail;
    },

    title() {
      return this.item.title;
    },
    author() {
      return this.item.owner;
    },
    views() {
      return this.item.views;
    },
    likes() {
      return this.item.likes;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  display: block;
  min-width: 280px;
  max-width: 380px;
  // align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-items: flex-start;
  // border: 1px solid rgba(0,0,0,0.2);
  margin: 0;
  margin-bottom: 15px;
  padding: 10px;
  // box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  width: auto;

  .thumbnail-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    img {
      // position: absolute;
      top: 0;

      width: 100%;
      height: 200px;
      object-fit: cover;

      border-radius: 0px;
      background-color: #eee;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(70%);
        transition: filter 0.3s;
      }
    }
  }

  .title * {
    color: #000;
  }

  .author *,
  .info * {
    color: #666;
  }
}
</style>