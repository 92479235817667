<template>
  <div class="video-wrapper">
    <div class="video-window" :style="{
      width: videoComponentWidth + 'px',
      height: videoComponentHeight + 'px',
    }">
      <video class="overlay video-2d" controlsList="nodownload" ref="videoComponent" preload="auto" playsinline
        v-show="projectionMode === '2d'" @loadedmetadata="onLoadedMetadata" @canplay="onCanPlay"
        @click="annotationHandler" @play="onPlayStateUpdate" @pause="onPlayStateUpdate">
        <source :src="`${videoURL}`" :type="videoType" />
      </video>
      <PanoVideo class="overlay video-3d" v-if="projectionMode !== '2d'" :projection-mode="projectionMode"
        :src="`${videoURL}`" :width="videoComponentWidth" :height="videoComponentHeight" :current-time="currentTime" />
      <div class="overlay" v-show="overlayEnabled" :style="{ pointerEvents: 'none' }">
        <div class="annotation">
          <div v-for="(item, key) in annotationRects" :key="key" class="overlay-tag" :style="item.style"
            @click="() => showAnnotation(item)" @error="hideIcon">
            <img v-if="item.src && item.src.length > 0" :src="item.src" />
            <h5 v-if="!item.src">{{ item.label }}</h5>
          </div>
        </div>
      </div>
      <seeker-bar class="seeker-bar" @seek="seek" :annotation="annotation" :currentTime="currentTime"
        :duration="duration" :width="videoComponentWidth" />
      <transition name="fade">
        <div :class="['popup', popupPosition]" ref="overlayPopUpDiv" v-show="overlayPopupVisible && overlayEnabled">
          <div class="popup-content" v-html="overlayPopup"></div>
          <button v-if="overlayPosition !== 'fixed'" class="close-button" @click="hideAnnotation">
            CLOSE
          </button>
        </div>
      </transition>
    </div>
    <div class="info-wrapper">
      <section class="title">
        <div class="inline">
          <h2>{{ title }}</h2>
          <button class="edit-button" @click="goEdit">EDIT</button>
          <!-- <button class="edit-button" @click="callRemote">2ND Screen</button> -->

          <span style="width: 50px">&nbsp;</span>

        </div>
      </section>
      <section class="description"></section>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import SeekerBar from "./SeekerBar.vue";
import PanoVideo from "./PanoVideo.vue";

import { socket } from "@/service/websocket";

export default {
  components: {
    SeekerBar,
    PanoVideo,
  },
  props: ["videoId", "width"],
  emits: ["resize", "timeupdate", "playstateupdate"],
  data() {
    return {
      deviceId: null,

      videoWidth: 320,
      videoHeight: 240,
      videoScale: 1.0,
      currentTime: 0,
      duration: 0,

      title: "[무제]",
      annotation: {},
      annotationRects: {},
      tickTimer: null,
      overlayPosition: "float",

      currentShowingAnnotationId: null,
      overlayPopup: "",
      overlayPopupVisible: false,
      // overlayEnabled: true,

      projectionMode: "2d",
    };
  },

  computed: {
    videoURL() {
      return process.env.BASE_URL + `api/video/${this.videoId}`;
    },

    videoType() {
      return "video/mp4";
    },

    videoComponentWidth() {
      return this.width;
    },

    videoComponentHeight() {
      return this.videoHeight * this.videoScale;
    },

    popupPosition() {
      return `popup-${this.overlayPosition}`;
    },

    overlayEnabled: {
      get() {
        return this.$store.state.showAnnotation;
      },
      set(value) {
        this.$store.commit("setShowAnnotation", value);
      },
    },
  },

  watch: {
    videoId() {
      this.$refs.videoComponent.load();
      this.loadAnnotation();
    },

    width() {
      this.adjustWindow();
    },
  },

  methods: {
    // Video Controls
    onLoadedMetadata() {
      this.duration = this.$refs.videoComponent.duration;
      this.adjustWindow();
    },

    onCanPlay() {
      setTimeout(() => {
        const el = this.$refs?.videoComponent;
        el && el.play();
      }, 100);
    },

    onPlayStateUpdate() {
      const paused = this.$refs?.videoComponent?.paused ? false : true;
      this.$emit("playstateupdate", paused);
    },

    async loadAnnotation() {
      this.annotation = (
        await this.$http.get(`/video/${this.videoId}/annotations`)
      ).data;

      // Common
      this.title = this.annotation?.header?.title ?? "[무제]";
      this.overlayPosition =
        this.annotation?.header?.overlayPosition ?? "float";

      if (this.tickTimer) window.cancelAnimationFrame(this.tickTimer);

      // Version 2
      if (this.annotation?.header?.version >= 2) {
        this.tickTimer = window.requestAnimationFrame(this.tick);
      }

      // projetion mode
      if (
        _.includes(
          ["180", "360", "360-CUBE"],
          this.annotation?.header?.projection
        )
      ) {
        this.projectionMode = this.annotation.header.projection;
      }
    },

    adjustWindow() {
      const componentWidth = this.videoComponentWidth;
      this.videoWidth = this.$refs.videoComponent.videoWidth ?? 0;
      this.videoHeight = this.$refs.videoComponent.videoHeight ?? 0;

      this.videoScale = componentWidth / this.videoWidth;
    },

    hideIcon(e) {
      e.target.style.visibility = "hidden";
    },

    async showAnnotation(item) {
      const video = this.$refs.videoComponent;
      let data = this.overlayPopup;
      if (this.currentShowingAnnotationId !== item.label) {
        console.log(`Load Annotation`, item);
        this.currentShowingAnnotationId = item.label;
        video.pause();
        const res = await this.$http.get(
          `/resources/videos/${this.videoId}/annotation/${item.content}`
        );

        if (res.status < 400) {
          data = res.data;

          this.overlayPopup = data;
          this.overlayPopupVisible = true;
        }
      }

      // Javascript execution override
      if (data.trim().startsWith("http")) {
        console.log("Direct link");
        window.location = data;
      }

      // Seek video override
      let match;
      match = data.match(/^\s*(seek)\s*:\s*([+-]?([0-9]*[.])?[0-9]*)\s*$/i);
      console.log(match);

      if (match) {
        this.overlayPopupVisible = false;
        const timeSeek = parseFloat(match[2]) + this.currentTime;
        console.log(`Seek to ${timeSeek}`);
        video.currentTime = timeSeek;
        video.play();
      }

      // Seek video override
      match = data.match(/^\s*(seekto)\s*:\s*(([0-9]*[.])?[0-9]*)\s*$/i);
      console.log(match);

      if (match) {
        this.overlayPopupVisible = false;
        const timeSeek = parseFloat(match[2]);
        console.log(`Seek to ${timeSeek}`);
        video.currentTime = timeSeek;
        video.play();
      }
    },

    async showAnnotationWithoutPause(item) {
      if (this.currentShowingAnnotationId === item.label) return;
      console.log(`Load Annotation`, item);
      this.currentShowingAnnotationId = item.label;
      const res = await this.$http.get(
        `/resources/videos/${this.videoId}/annotation/${item.content}`
      );

      if (res.status < 400) {
        const data = res.data;

        this.overlayPopup = data;
        this.overlayPopupVisible = true;
      }
    },

    hideAnnotation() {
      this.overlayPopupVisible = false;
      this.currentShowingAnnotationId = null;
    },

    annotationHandler(e) {
      e.preventDefault();
      const video = this.$refs.videoComponent;

      this.playPause();
    },

    tick() {
      const { currentTime } = this.$refs.videoComponent;
      this.currentTime = currentTime;
      this.$emit("timeupdate", currentTime);

      const { metadataObject } = this.annotation;

      if (!metadataObject) {
        this.tickTimer = window.requestAnimationFrame(this.tick);
        return;
      }

      const inArr = _.filter(metadataObject, (o) => {
        return o.timeRange[0] <= currentTime && o.timeRange[1] >= currentTime;
      });
      const outArr = _.filter(metadataObject, (o) => {
        return o.timeRange[0] > currentTime || o.timeRange[1] < currentTime;
      });

      const { width: videoWidth, height: videoHeight } =
        this.$refs.videoComponent.getBoundingClientRect();

      // Incoming
      for (const inObj of inArr) {
        const src = inObj.img;
        const style = {
          position: "absolute",
        };

        if (src) {
          // No bounding box
          style.border = "none";
        }

        const i1 = _.findLastIndex(inObj.bbox, (o) => o[0] <= currentTime);
        const i2 = _.findIndex(inObj.bbox, (o) => o[0] >= currentTime);

        const [s1, [p1x1, p1y1], [p1x2, p1y2]] = inObj.bbox[i1];
        const [s2, [p2x1, p2y1], [p2x2, p2y2]] = inObj.bbox[i2];

        const t = (currentTime - s1) / (s2 - s1);

        const left = p1x1 + (p2x1 - p1x1) * t;
        const top = p1y1 + (p2y1 - p1y1) * t;
        const right = p1x2 + (p2x2 - p1x2) * t;
        const bottom = p1y2 + (p2y2 - p1y2) * t;
        const width = right - left;
        const height = bottom - top;

        style.left = `${left * videoWidth}px`;
        style.top = `${top * videoHeight}px`;
        style.width = `${width * videoWidth}px`;
        style.height = `${height * videoHeight}px`;

        this.annotationRects[inObj.id] = {
          src: (src && `${this.videoURL}/annotation/${src}`) || null,
          style: style,
          label: inObj.name,
          content: inObj.content,
        };
      }

      // Override for bottom always on annotation box
      if (
        this.annotation?.header?.overlayPosition === "fixed" &&
        Object.keys(this.annotationRects).length > 0
      ) {
        this.showAnnotationWithoutPause(
          this.annotationRects[Object.keys(this.annotationRects)[0]]
        );
      } else {
        //
      }

      // Outgoing
      for (const outObj of outArr) {
        delete this.annotationRects[outObj.id];
      }

      this.tickTimer = window.requestAnimationFrame(this.tick);
    },

    seek(time) {
      this.$refs.videoComponent.currentTime = time;
    },

    playPause() {
      const video = this.$refs.videoComponent;

      if (video.paused) {
        video.play();
        if (this.overlayPosition !== "fixed") {
          this.hideAnnotation();
        }
      } else {
        video.pause();
      }
    },

    play() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.play();
      }
    },

    pause() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.pause();
      }
    },

    goEdit() {
      this.$router.push("/edit/" + this.videoId);
    },

    likeVideo() {
      this.$http.post(`/video/${this.videoId}/like`);
    },

    dislikeVideo() {
      this.$http.post(`/video/${this.videoId}/dislike`);
    },

    callRemote() {
      const username = _.get(this.$store.state, "user.username");

      if (username) {
        this.overlayEnabled = false;
        socket.emit("call-remote", {
          from: this.deviceId,
          user: username,
          emitTime: Date.now(),
          room: this.deviceId,
          videoId: this.videoId,
          videoTime: this.currentTime,
        });
      }
    },
  },

  async mounted() {
    this.deviceId = window.localStorage.getItem("deviceId");
    this.$refs.videoComponent.disablePictureInPicture = true;
    await this.loadAnnotation();

    // Always popup show when overlayPosition is fixed
    if (this.overlayPosition === "fixed") {
      this.overlayPopupVisible = true;
    }
    if (this.annotation?.header?.overlayStyle) {
      const newStyle = this.annotation?.header?.overlayStyle;
      this.$refs.overlayPopUpDiv.style = newStyle;
    }
  },

  beforeUnmount() {
    this.pause();
    window.cancelAnimationFrame(this.tickTimer);
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  .video-window {
    position: relative;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;

    img {
      object-fit: cover;
    }
  }

  .annotation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .overlay-tag {
      /* border: 1px solid blue; */
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      object-fit: contain;

      &:hover {
        border: 3px solid rgba(0, 0, 255, 0.3);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h5 {
      visibility: hidden;
      color: white;
      line-height: 1.5em;
      background-color: blue;
      margin: 0;
      padding: 0;
    }
  }

  .seeker-bar {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 0px;
    z-index: 10;
  }

  .popup {
    z-index: 190;
  }

  .popup-float {
    position: absolute;
    margin-left: auto;
    top: 10px;
    right: 10px;
    min-width: 100px;
    max-width: 50%;
    min-height: 30%;
    max-height: 90%;
    overflow: scroll;
    border: none;
    /* 2px dashed #ccc; */
    background: transparent;
    /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .popup-fixed {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 1%;
    overflow: hidden;
    border: none;
    /* 2px dashed #ccc; */
    background: transparent;
    /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .popup .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 80px;
    height: 30px;
    border: 3px solid red;
    color: red;
    background-color: white;
    z-index: 30;
    font-family: monospace;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    padding: 1px;

    &:hover {
      color: white;
      background-color: red;
    }
  }
}

.info-wrapper {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.inline {
  display: flex;
  justify-content: flex-start;
}

.edit-button {
  margin-left: 10px;
  height: auto;
}
</style>