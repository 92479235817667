<template>
  <div ref="videoWrapper" class="remote-control-view">
    <annotation-video-player
      ref="videoPlayer"
      :videoId="videoId"
      :width="componentWidth"
      @timeupdate="timeUpdate"
    />
  </div>
</template>

<script>
import _ from "lodash";

// import Tile from '@/components/Tile.vue';
import AnnotationVideoPlayer from "@/components/RemoteControlAnnotationVideoPlayer";

export default {
  components: {
    // Tile,
    AnnotationVideoPlayer,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentWidth: 0,
      items: [],
      currentTime: 0,
      syncMessageEmitter: null,
    };
  },

  methods: {
    adjustWindow() {
      const { width } = this.$refs.videoWrapper.getBoundingClientRect();
      this.componentWidth = width;
    },

    timeUpdate(currentTime) {
      this.currentTime = currentTime;
    },
  },

  mounted() {
    window.addEventListener("resize", this.adjustWindow);
    this.adjustWindow();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.adjustWindow);
  },
};
</script>

<style lang="scss" scoped>
.remote-control-view {
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 0;

  background-color: #eee;
}
</style>