<template>
  <div class="video-view" ref="videoWrapper">
    <annotation-video-player ref="videoPlayer" :videoId="videoId" :width="componentWidth" @timeupdate="onTimeUpdate"
      @playstateupdate="onPlayStateUpdate" />
    <div class="qr-container">
      <a :href="remotecontrolLink" target="remotecontrol">
        <canvas ref="qrcode" width="200px" height="200px" />
      </a>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AnnotationVideoPlayer from "@/components/AnnotationVideoPlayer";

import { socket } from "@/service/websocket";
import QRCode from "qrcode";
import { ref, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

import { useStore } from "vuex";


export default {
  components: {
    AnnotationVideoPlayer,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props/*, context */) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { videoId } = props;

    const componentWidth = ref(0);
    const currentTime = ref(0);

    const deviceId = ref("");
    const syncMessageEmitter = ref(null);

    // ref: videoWrapper div
    const videoWrapper = ref(null);

    const adjustWindow = () => {
      const rect = videoWrapper.value?.getBoundingClientRect();
      if (!rect) {
        console.error("videoWrapper is null");
      }
      const { width } = rect;
      componentWidth.value = width;
    };


    const onTimeUpdate = (newCurrentTime) => {
      currentTime.value = newCurrentTime;
    };

    const onPlayStateUpdate = (isPlaying) => {
      const username = _.get(store.state, "user.username");
      const emitParams = {
        from: deviceId.value,
        user: username,
        emitTime: Date.now(),
        room: deviceId.value,
        videoId: videoId.value,
        videoTime: currentTime.value,
      };

      if (isPlaying) {
        socket.emit("play", emitParams);
      } else {
        socket.emit("pause", emitParams);
      }
    };

    const qrcode = ref(null);
    const remotecontrolLink = ref("");
    const { proxy } = getCurrentInstance();

    onMounted(async () => {
      deviceId.value = window.localStorage.getItem("deviceId");
      window.addEventListener("resize", adjustWindow);
      adjustWindow();

      socket.emit("join", {
        from: deviceId.value,
        room: deviceId.value,
      });

      syncMessageEmitter.value = setInterval(() => {
        const username = _.get(store.state, "user.username");

        socket.emit("sync", {
          from: deviceId.value,
          user: username,
          emitTime: Date.now(),
          room: deviceId.value,
          videoId: videoId,
          videoTime: currentTime.value,
        });
      }, 500);

      socket.on("call-remote", (msg) => {
        const videoId = msg.videoId;
        console.log(`Jump to ${videoId}`);
        router.push("/m/watch/" + videoId);
      });

      const { protocol, host } = new URL(window.location);
      remotecontrolLink.value = `${protocol}//${host}/m?room=${deviceId}`;
      QRCode.toCanvas(qrcode.value, remotecontrolLink.value);
    });

    onBeforeUnmount(() => {
      clearInterval(syncMessageEmitter.value);
    })

    return {
      deviceId,
      componentWidth,
      currentTime,
      syncMessageEmitter,
      qrcode,
      remotecontrolLink,

      videoWrapper,
      onTimeUpdate,
      onPlayStateUpdate,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-view {
  width: 100%;
  height: auto;
  overflow: visible;
  padding: 0;

  background-color: #eee;

}

.container {
  display: flex;
  grid-template-columns: auto 300px;
  grid-template-rows: auto;
  grid-template-areas: "main bottom";
}

.qr-container {
  position: fixed;
  top: 100px;
  right: 10px;
  width: 165px;
  height: 165px;
  z-index: 30;
}
</style>