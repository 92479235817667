<template>
  <nav class="navbar">
    <LoginModal :visible="loginModalVisible" @loginSuccess="loginSuccess" />
    <div class="col1 brand">
      <router-link to="/">
        <img class="logo" src="@/assets/ysm-logo.png" alt="Your Smart Media" />
      </router-link>
    </div>
    <div class="col3">
      <router-link v-if="user" to="/upload">
        <button class="btn btn-round btn-pink btn-create-contents">
          <img src="@/assets/magic-wand-white.png" />
          <span>컨텐츠 제작</span>
        </button>
      </router-link>
    </div>
    <div class="col4 link-no-decoration">
      <div>
        <button v-if="user" class="btn btn-round btn-login" @click="logout">
          <span>LOGOUT <i class="fas fa-plus"></i></span>
        </button>
        <button v-else class="btn btn-round btn-login" @click="showLoginModal">
          <span>LOGIN <i class="fas fa-plus"></i></span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import LoginModal from "@/components/LoginModal.vue";

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      loginModalVisible: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    showLoginModal() {
      this.loginModalVisible = true;
    },
    hideLoginModal() {
      this.loginModalVisible = false;
    },
    async logout() {
      await this.$http.get("/logout");
      this.$store.commit("setUser", null);
      this.$router.push("/");
    },
    doSearch(e) {
      this.$router.push(`/?title=${e.target.value}`);
    },
    loginSuccess() {
      this.hideLoginModal();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/variables.scss";

.navbar {
  display: grid;
  width: 100%;
  min-width: 1280px;
  height: 100%;
  padding: 30px;
  grid-template-columns: 350px minmax(300px, 1fr) 150px 450px;
  grid-template-rows: 1fr;
  grid-gap: 20px;

  .col1 {
    grid-column: 1;
    grid-row: 1;
  }

  .col2 {
    grid-column: 2;
    grid-row: 1;
  }

  .col3 {
    grid-column: 3;
    grid-row: 1;
  }

  .col4 {
    grid-column: 4;
    grid-row: 1;

    & > div {
      display: inline-block;
      margin: 0 20px;
    }
  }

  .searchbar-wrapper {
    min-width: 400px;
    margin-left: 50px;
    height: 40px;
    max-height: 40px;
    border-radius: 20px;
    border: 2px solid $pink;
    padding: 0 20px;

    * {
      position: relative;
      display: inline;
      padding: 10px 0;
      border: none;
      background-color: transparent;
      font-size: 15px;
      color: rgb(153, 153, 153);

      max-height: 40px !important;
    }

    .separator {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
    }

    select {
      width: 75px;

      &:focus {
        outline: none;
      }
    }

    input {
      padding-left: 0px;
      color: #000;
      width: calc(100% - 200px);

      &:focus {
        outline: none;
      }
    }

    .right-icon {
      float: right;
      width: 60px;
      height: 40px;
      line-height: 40px;
      margin: 0;
      padding: 0;

      span {
        display: inline;
        height: 20px;
        line-height: 20px;

        i {
          color: #333;
          height: 10px;
          font-size: 20px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .btn-login {
    color: $pink;

    &:hover {
      background-color: $pink;
      color: #fff;
      transition: background-color 0.3s, color 0.3s;
    }
  }
}
</style>